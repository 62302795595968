@import 'helsinki/colors';
$selectorBorderWidth: 2px;

.vene-application-selector {
  background: $hel-fog;
  padding: 1.5em 0 0.5em;
  border-top: $selectorBorderWidth solid $hel-black;
  border-bottom: $selectorBorderWidth solid $hel-black;

  .vene-alert {
    margin-bottom: 0;
    margin-top: 2em;
  }
}
