@import 'styles/mixin';
@import 'helsinki/colors';
@import 'helsinki/fonts';
@import 'styles/variables';

.vene-payment-page {
  background-color: $hel-light;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__content-container {
    display: flex;
    padding: $spacing-04 0;

    &:first-child {
      background-color: $hel-fog;
    }
    &:last-child {
      padding-top: $spacing-02;
    }
    @include for-desktop {
      justify-content: center;
    }
  }

  &__subheading {
    font-size: $font-size-lg;
  }

  &__content {
    margin: 0 $spacing-01;

    @include for-desktop {
      width: $spacing-40;
    }
    @include for-phone {
      width: 100%;
    }
  }

  &__accept-terms-content {
    > * {
      margin-bottom: $spacing-03;
    }
  }

  &__notice {
    font-size: $font-size-sm;
  }

  &__link {
    color: $hel-coat;
  }

  &__pay-button {
    padding: $spacing-01 $spacing-01;

    &:disabled {
      background: $hel-gray;
      border-color: $hel-gray;
    }
    @include for-phone {
      width: 100%;
    }
  }

  &__contact-info {
    margin-bottom: 0;
  }

  &__signing-providers {
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: $spacing-01;
  }
}
