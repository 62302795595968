.vene-formfield {
  &__label {
    &.is-required {
      &::after {
        margin-left: 1ch;
        content: '*';
        color: red;
      }
    }
  }
}
