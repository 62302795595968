@import 'helsinki/fonts';

.vene-reservation-history {
  &__heading {
    font-size: $font-size-base;
  }

  &__list {
    list-style: none;
    padding: 0;
  }
}
