@import 'helsinki/colors';
@import 'styles/variables';

.vene-place-info {
  margin-bottom: $spacing-02;

  &__main {
    display: flex;
  }

  &__image {
    margin-right: $spacing-01-50;
    width: $spacing-12;
  }

  &__area-name {
    font-size: $spacing-01-50;
    margin: $spacing-00-25 0;
  }

  &__link {
    text-decoration: underline;
    margin-bottom: $spacing-00-25;
    display: block;
  }

  &__normal-label {
    font-weight: normal;
  }

  &__bold-field {
    font-size: $spacing-01;
    font-weight: bold;
  }
}
