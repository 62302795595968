.vene-front-page {
  margin-top: 3em;

  &__title {
    margin-bottom: 2em;
  }

  &__card-wrapper {
    padding: 10px;
  }
}
