@import 'helsinki/colors';
@import 'helsinki/fonts';

.vene-unmarked-winter-storage-legend {
  .vene-steps {
    margin-top: 0;
  }
  &__header {
    margin-bottom: 2em;
  }
}
