@import 'helsinki/colors';
@import 'helsinki/fonts';
@import 'styles/mixin';

.vene-form {
  &__wizard-wrapper {
    background-color: $hel-light;
    padding: 1em 0 3em;

    &__button-group {
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      button {
        &:last-child {
          span {
            font-weight: $font-weight-bold;
            font-size: $font-size-lg;
          }
        }
      }
      @include for-phone {
        flex-direction: column;
      }
    }
  }
}
