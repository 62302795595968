@import 'helsinki/colors';
@import 'styles/mixin';
@import 'helsinki/fonts';

.vene-section-selector {
  background-color: $hel-light;
  padding: 1em;

  &__link {
    display: flex;
    flex-direction: column;

    color: inherit;
    padding: 1em;
    text-align: center;
    min-height: 8em;
    background-color: 'unset';
    height: 100%;

    span {
      font-weight: $font-weight-bold;
    }
    .vene-icon {
      margin: 0 auto;
      fill: $hel-black;
      width: 50%;
    }

    &:hover,
    &:active {
      text-decoration: none;
      color: inherit;
    }

    svg {
      max-height: 6em;
    }

    &--is-selected {
      background-color: $hel-fog;
    }

    font-size: 0.8em;
    line-height: 1;

    @include for-phone {
      font-size: 1em;
      font-weight: $font-weight-normal;
      &.is-selected {
        font-weight: $font-weight-bold;
      }
    }
  }
}
