@import 'styles/variables';

.vene-modal {
  top: 10vh;

  &__body {
    margin-top: $spacing-00-75;
    margin-bottom: $spacing-00-75;
  }

  &__btn {
    flex: 1;
  }
}
