@import 'helsinki/colors';
@import 'styles/mixin';
@import 'styles/variables';
@import 'helsinki/fonts';

.vene-selected-berth-page {
  &__wrapper {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  &__button-wrapper {
    background-color: $hel-light;
    padding: 1em 0 3em;

    &__button-groups {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      button {
        &:last-child {
          span {
            font-weight: $font-weight-bold;
            font-size: $font-size-lg;
          }
        }
      }
      @include for-phone {
        flex-direction: column;
      }
    }
  }

  &__berth {
    margin-bottom: $spacing-01;
  }

  &__boat-value {
    margin-left: 1ch;
  }

  &__notice {
    display: flex;
    justify-content: left;
    flex-direction: row;

    .vene-icon {
      fill: $hel-brick;
      width: 1.5em;
      height: 1.5em;
      margin-right: 0.5em;
    }

    a {
      color: $hel-black;
      text-decoration: underline;
    }
  }
}
