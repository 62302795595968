@import 'helsinki/colors';
@import 'styles/variables';
@import 'styles/mixin';

.vene-offer {
  &__heading {
    line-height: $spacing-02-50;
    margin-bottom: $spacing-04;
  }

  &__grid {
    display: grid;
    grid-column-gap: $spacing-04;
    grid-template-columns: 7fr 5fr;
    grid-template-rows: auto;
    grid-template-areas:
      'place-info place-info'
      'order-info order-info'
      'buttons buttons'
      'info-text info-text';

    @include for-desktop() {
      grid-template-areas:
        'place-info order-info'
        'buttons order-info'
        'info-text order-info';
    }
  }

  &__place-info {
    grid-area: place-info;
  }

  &__buttons {
    grid-area: buttons;
    margin: $spacing-02 0;

    > button:not(:first-child) {
      margin-left: $spacing-01;
    }
  }

  &__info-text {
    grid-area: info-text;
  }

  &__order-info {
    grid-area: order-info;
  }
}
