@import 'helsinki/colors';
@import 'helsinki/fonts';
@import 'styles/mixin';

.vene-step {
  flex: 1;
  color: inherit;
  text-align: left;
  hyphens: auto;

  &__link {
    &:hover,
    &:active {
      text-decoration: none;
      color: $hel-black;
    }
  }

  &__status {
    height: 1em;
    margin: auto;
    margin-bottom: 0.5em;
    padding-top: 0.1em;
    border-radius: 0.67em;
    background-color: $hel-light;

    &.current,
    &.completed {
      background-color: $hel-coat;
    }
  }

  &__label {
    font-weight: $display1-weight;
    font-size: $font-size-sm;
    line-height: 1;
    padding-left: 0.3em;
    color: $hel-black;

    @include for-tablet {
      font-size: $font-size-sm;
    }
  }
}
