@import 'helsinki/colors';
@import 'styles/variables';

.vene-tabs {
  &__tablist {
    margin: $spacing-02 0;
  }

  &__tab {
    position: relative;
    // Adjusting the colors here until HDS supports themes for Tabs
    --tab-color: black;
    --tab-active-border-color: black;

    &--with-badge {
      &::after {
        content: '';
        display: block;
        background: $hel-brick;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: $spacing-00-25;
      }
    }
  }
}
