@import 'styles/mixin';
@import 'helsinki/colors';

.vene-hero {
  background-size: cover;
  background-position: right top;
  padding: 4em 0;
  min-height: 10em;
  @include for-tablet {
    min-height: 20em;
  }
  @include for-big-desktop {
    min-height: 30em;
  }

  &__title {
    font-size: 3em;
    hyphens: auto;
    overflow-wrap: break-word;

    @include for-phone {
      font-size: 2em;
    }
    @include for-desktop {
      font-size: 4em;
    }
    color: $hel-white;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  }
}
