@import 'helsinki/colors';
@import 'styles/variables';

.vene-profile-property {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $spacing-04;
  margin-right: $spacing-00-50;

  &__icon {
    padding: 2px;
    height: $spacing-02-50;
    width: $spacing-02-50;
    border: 2px solid $hel-black;
    border-radius: 50%;
  }

  &__label {
    font-size: $spacing-00-75;
    font-weight: bold;
    text-align: center;
    margin: 0;
  }
}
