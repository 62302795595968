@import 'helsinki/mixins';
@import 'helsinki/colors';
@import 'styles/mixin';

$white: $hel-white;
$fog: $hel-fog;
$blue: $hel-coat;
$divider-height: 1.25em;

@mixin divider($color: $white) {
  background-color: $color;

  &::before {
    display: block;
    position: absolute;
    content: '';
    height: $divider-height;
    width: 100%;
    top: -$divider-height;
    @include koro('basic', $color, 400);
  }
}

.section-koro {
  position: relative;
  padding: 4em 0 5em;

  @include divider;

  &__title {
    hyphens: auto;
    overflow-wrap: break-word;
    font-size: 2.5rem;
    margin-bottom: 1em;

    @include for-phone {
      font-size: 2em;
      text-align: center;
    }
  }

  &--fog {
    @include divider($fog);
  }

  &--blue {
    @include divider($blue);
  }

  &--bottom {
    padding-bottom: 2em;

    &::before {
      top: unset;
      bottom: -$divider-height;
      transform: scaleY(-1);
      z-index: 9;
    }
  }

  &--centered {
    text-align: center;
  }
}
