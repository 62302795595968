@import 'helsinki/colors';
@import 'helsinki/fonts';
@import 'styles/mixin';

.vene-tab-selector {
  &__header {
    display: flex;
    flex-direction: column-reverse;
    background-color: $hel-fog;

    @include for-tablet {
      flex-direction: row;
    }
  }

  &__tab-button:not(:disabled):not(.disabled) {
    outline: none;
    border: none;
    padding: 0.5em 1em;
    border-radius: 0;
    background-color: transparent;

    span {
      color: $hel-black;
      font-weight: $font-weight-bold;
    }

    &:focus {
      span {
        color: #0072c6;
      }
    }

    &.active {
      background-color: $hel-white;

      &:hover {
        background-color: lighten($hel-fog, 15%);
      }
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      background-color: lighten($hel-fog, 10%);
      cursor: pointer;
    }

    @include for-phone {
      padding: 1em 2em;
    }
  }

  &__tabs {
    margin-top: 0;
    margin-bottom: 5em;
  }

  &__application-prompt {
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    z-index: 1001;
    background-color: $hel-light;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);

    @include for-ie {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: $font-size-sm;
      flex-grow: 1;
      line-height: 1;
      text-align: right;
      padding: 0.5em 0;
    }

    @include for-tablet {
      font-size: inherit;
    }
  }

  &__progress-button {
    float: right;
    margin-left: 1ch;
    font-size: 14px;

    @include for-tablet {
      font-size: inherit;
    }
  }
}
