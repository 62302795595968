@import 'helsinki/colors';

.vene-availability-level {
  &::before {
    display: inline-block;
    content: '';
    width: 0.75em;
    height: 0.75em;
    margin-right: 0.5em;
    border-radius: 50%;
    background-color: $hel-gray;
    vertical-align: baseline;
  }
  &--green {
    &::before {
      background-color: $hel-tram;
    }
  }
  &--yellow {
    &::before {
      background-color: $hel-summer;
    }
  }
  &--red {
    &::before {
      background-color: $hel-brick;
    }
  }
}
