$spacing-base: 1rem;

$spacing-00-25: $spacing-base * 0.25;
$spacing-00-50: $spacing-base * 0.5;
$spacing-00-75: $spacing-base * 0.75;
$spacing-01: $spacing-base * 1;
$spacing-01-50: $spacing-base * 1.5;
$spacing-02: $spacing-base * 2;
$spacing-02-50: $spacing-base * 2.5;
$spacing-03: $spacing-base * 3;
$spacing-04: $spacing-base * 4;
$spacing-06: $spacing-base * 6;
$spacing-08: $spacing-base * 8;
$spacing-12: $spacing-base * 12;
$spacing-16: $spacing-base * 16;
$spacing-24: $spacing-base * 24;
$spacing-32: $spacing-base * 32;
$spacing-40: $spacing-base * 40;
$spacing-48: $spacing-base * 48;
