@import 'helsinki/fonts';
@import 'helsinki/colors';
@import 'styles/variables';

.vene-contract-info {
  &__heading {
    font-size: $font-size-base;
  }

  &__link {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    margin: $spacing-01 0 0;
    padding: 0;
    color: $hel-bus;
  }
}
