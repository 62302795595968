@import 'helsinki/colors';
@import 'helsinki/fonts';

@import 'styles/variables';
@import 'styles/mixin';

$imageHeight: 12em;

.vene-area-card {
  background-color: $hel-light;

  h5 {
    line-height: 1;
  }

  &__image {
    position: relative;
    height: 100%;

    .alert {
      position: absolute;
      margin: $spacing-01;
      padding: $spacing-00-50 $spacing-01;
    }
    .vene-image {
      height: 100%;
      img {
        object-fit: cover;
        height: $imageHeight;
        width: 100%;
        display: none;

        @include for-tablet {
          height: 100%;
          display: block;
        }
      }
    }
  }

  &__availability-level {
    display: block;
    margin: $spacing-00-50 0;
  }

  &__summary-wrapper {
    height: 100%;
    padding: 1em;

    .vene-icon {
      height: $spacing-01;
      width: $spacing-01;
      fill: $hel-white;
      display: inline-block;
      margin-right: $spacing-00-25;
    }

    strong {
      line-height: 1em;
      font-size: $font-size-sm;

      @include for-tablet {
        font-size: $font-size-base;
      }

      @include for-desktop {
        font-size: $font-size-lg;
      }
    }

    small {
      font-size: $font-size-sm;
    }

    a {
      color: black;
    }

    @include for-tablet {
      padding: $spacing-01 0;
    }
  }

  &__address {
    margin-bottom: $spacing-01;
    line-height: 1;
  }

  &__website-link {
    span {
      margin-top: 0.5em;
      font-weight: $font-weight-bold;
    }

    .vene-icon {
      height: $spacing-01;
      width: $spacing-01;
      fill: $hel-black;
      display: inline-block;
    }
  }

  &__details-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 100%;
    padding: $spacing-01 0;
    white-space: nowrap;
  }
}
