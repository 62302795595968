@import 'helsinki/fonts';
@import 'helsinki/colors';

.vene-unregistered-boat-detail {
  .vene-input {
    &.custom-checkbox {
      margin-top: 1.5rem;
      line-height: 1.25;
      span {
        display: block;
        font-size: $font-size-sm;
        margin-top: 0.5rem;
        line-height: 1.25;
      }
    }
  }

  .vene-unregistered-boat__input {
    color: $hel-black;
  }
}
