.vene-navbar {
  // Various fixes for overrides caused by site theme

  a[class^='Navigation-module_title'] {
    &:hover {
      color: inherit;
      text-decoration: none;
    }

    > span {
      font-weight: inherit;
      font-size: inherit;
    }
  }

  span[class^='MenuButton-module_toggleButtonLabel'] {
    font-weight: inherit;
  }

  a[class^='NavigationItem-module_rowItem'] {
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}
