@import 'helsinki/colors';
@import 'helsinki/fonts';
@import 'styles/mixin';
@import 'styles/variables';

.vene-selected-berth {
  position: relative;

  &__info {
    &--moving-entering,
    &--moving-exiting {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $hel-white;
        opacity: 0.4;
      }
    }
  }

  &__title-bar {
    padding: $spacing-00-50 0;
    background-color: $hel-coat;
    color: $hel-white;

    &--has-error {
      background-color: lighten($hel-brick, 50%);
      color: $hel-black;

      .vene-selected-berth__close-btn {
        fill: $hel-black;
      }
    }
  }

  &__title {
    padding-right: 0;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    font-size: $font-size-lg;
    line-height: 1;
  }

  &__services-bar {
    padding: $spacing-00-50 0;
    background-color: $hel-light;
  }

  &__close {
    padding-left: 0;
  }

  &__close-btn {
    height: $spacing-02;
    width: $spacing-02;
    fill: $hel-white;
  }

  &__availability-level {
    padding-right: 0;

    .vene-availability-level {
      font-size: $font-size-sm;
    }
  }

  &__services {
    padding-left: 0;
    margin-top: $spacing-00-50;

    @include for-tablet {
      margin-top: 0;
      text-align: right;
    }
  }

  &__service-icn {
    height: $spacing-01-50;
    width: $spacing-01-50;
    fill: $hel-black;
    margin-left: $spacing-00-75;
    vertical-align: baseline;

    &--disabled {
      fill: lighten($hel-black, 50%);
    }
  }

  &__ctrl {
    padding: 0;
    padding-left: $spacing-00-25;
  }

  &__arrow-btn {
    $margin: $spacing-00-25;
    padding: 0;
    margin-left: $margin;
    height: 3em;
    width: calc(50% - #{$margin});

    &:hover {
      background-color: $hel-fog;
    }

    &.disabled {
      opacity: 0.2;
      &:hover {
        background-color: transparent;
      }
    }

    @include for-phone {
      width: calc(100% - #{$margin});
      height: calc(50% - #{$margin} / 2);

      &:first-child {
        margin-bottom: $spacing-00-25;
      }
    }
  }

  &__arrow-icon {
    height: $spacing-02;
    width: $spacing-02;
  }
}
