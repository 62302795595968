body,
span,
textarea,
button,
div {
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
}

span,
div {
  font-size: $font-size-base;
}

h1,
h2,
h3,
h4,
h5,
strong {
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
}

.custom-select {
  background-color: transparent;

  &:invalid {
    color: $input-placeholder-color;
  }

  [disabled] {
    color: $input-placeholder-color;
  }

  option {
    color: $input-color;
  }
}

.btn-link.btn-link {
  color: $hel-black;
  text-decoration: none;
  &:active,
  &:hover {
    color: $hel-black;
    background-color: #fff;
    outline: none;
    box-shadow: none;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000;
  text-decoration: none;
  background-color: #fff;
}

h1 {
  line-height: 1;
  margin-bottom: 2rem;
}

h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.custom-control {
  padding-left: 2rem;
}
.custom-control-label {
  &::before {
    left: -2rem;
  }
  &::after {
    left: -2rem;
  }
}
