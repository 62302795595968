$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

@mixin for-phone {
  @media (max-width: $sm) {
    @content;
  }
}
@mixin for-tablet {
  @media (min-width: $md) {
    @content;
  }
}

@mixin for-desktop {
  @media (min-width: $lg) {
    @content;
  }
}
@mixin for-big-desktop {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin for-ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
