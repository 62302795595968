@import 'helsinki/colors';

.vene-card {
  padding: 1.5em;
  background-color: $hel-white;
  text-align: left;
  min-width: 8em;
  height: 100%;

  &__title {
    margin-top: 0;
    margin-bottom: 1em;
    font-size: 1.25rem;
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__description {
    flex-grow: 1;
  }

  &__button {
    display: flex;
    margin: 1em auto 0;
    padding: 0.75em 1em;
    line-height: 1.5;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  &__button-label {
    font-weight: inherit;
    white-space: pre-wrap;
  }

  &__arrow-icon {
    margin-left: 1rem;
    height: 1.5em;
    color: inherit;
    fill: currentColor;
  }
}
